/* 一个聊天窗口，窗口内聊天的内容是矩形的（四周有稍微的弧度，根据文本的长度矩形长度随之改变），点击左下角的按钮发送的消息会在左方对齐，点击右下角按钮发送的消息会右方对齐，左侧和右侧发送的消息在同一个队列，新的会把老的推上去。 */
import React, { Component } from "react";
import { Card, Typography, TextField, Grid, Button } from "@mui/material";
import { MessageList } from "./MessageList";
import $ from "jquery";
import Papa from 'papaparse';

export class ChatRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollHeight: 0,
      message: "",
      messages: [],
    };
  }

  componentDidUpdate() {
    const element = document.getElementById("ScrollScan");
    element.scrollTop = element.scrollHeight;
  }

  //Handle user message
  handleMessage(e) {
    this.setState({
      message: e.target.value,
    });
  }

  sendQuestion(e) {
    //Changed to sendQuestion(e)
    e.preventDefault();
    let ask = this.state.message;
    console.log(ask);

    $.ajax({
      url: "http://localhost:8080/openai",
      type: "post",
      data: JSON.stringify({
        question: ask,
      }),
      contentType: "application/json",
      dataType: "json",
      success: (data) => {
        //Use arrow function to keep context of this
        console.log(data);
        this.setState((state) => ({
          message: "",
          messages: [...state.messages, { type: "left", text: data.data }], //Update state with data from ajax request
        }));
        // document.querySelector("#answer-container").innerHTML = answerDiv;
      },
    });
  }

  //Send message from right
  addToMessagesList(e) {
    e.preventDefault();
    this.setState({
      message: "",
      messages: [
        ...this.state.messages,
        { type: "right" , text: this.state.message },
      ],
    });
  }

  // convertToCSV = (messages) => {
  //   let csvString = "";
  //   csvString += "type,message\n";
  //   // 如果存在双引号，需要将字符串的一个双引号 替换为 两个双引号。并且需要在字符串的前后加上双引号。如果只存在逗号（不存在双引号），将前后加双引号即可

  //   // Loop through the array of messages and create a string with comma separated values
  //   var quoteFlag = false;
  //   for (let message of messages) {
  //     let tmp = message.text;
  //     tmp = tmp.replace(/[,，]/g, "<br>").replace(/\n/g, "");
  //     tmp.replace(/["]/g, '""').replace(/\n/g, "");
  //     csvString += `${message.type},"${tmp}"\n`;
  //   }
  //   return csvString;
  // };

  convertToCSV(messages) {
    const csvData = Papa.unparse(messages);
    return csvData;
  }

  render() {
    return (
      <Card style={{ width: "500px", margin: "20px" }}>
        {/* <Grid container> */}
        <Typography variant="h5">Chat Room</Typography>

        <div
          id="ScrollScan"
          style={{
            width: "500px",
            height: "400px",
            overflow: "auto",
            scrollTop: this.state.scrollHeight,
            scrollbarWidth: "3px",
          }}
        >
          <Grid item xs={12}>
            <MessageList messages={this.state.messages} />
          </Grid>
        </div>
        {/* </Grid> */}
        <Grid container>
          <Grid item xs={12}>
            <TextField
              multiline
              label="Message"
              value={this.state.message}
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.ctrlKey) {
                  this.addToMessagesList(e);
                  this.sendQuestion(e);
                }
              }}
              onChange={(e) => this.handleMessage(e)}
              style={{ width: "500px", borderRadius: "10px" }}
            />
          </Grid>
          <Grid container justify="center">
            <Button
              onClick={(e) => {
                this.addToMessagesList(e);
                this.sendQuestion(e);
              }}
              style={{
                width: "500px",
                borderRadius: "10px",
                marginTop: "10px",
              }}
              disabled={this.state.message === ""}
            >
              Send Right
            </Button>

            <Button
              onClick={() => {
                const element = document.createElement("a");
                const file = new Blob([JSON.stringify(this.state.messages)], {
                  type: "application/json",
                });

                element.href = URL.createObjectURL(file);
                element.download = "messages-data";
                document.body.appendChild(element);
                element.click();
              }}
            >
              JSON Download
            </Button>
            <Button
              onClick={() => {
                const element = document.createElement("a");
                const file = new Blob(
                  [this.convertToCSV(this.state.messages)],
                  {
                    type: "text/csv",
                  }
                );
                element.href = URL.createObjectURL(file);
                element.download = "messages-data";
                document.body.appendChild(element);
                element.click();
              }}
            >
              CSV Download
            </Button>
            <Button
              onClick={() => {
                let input = document.createElement("input");
                input.type = "file";
                input.accept = ".json";

                input.addEventListener("change", (e) => {
                  let file = e.target.files[0];
                  let reader = new FileReader();
                  reader.onload = (e) => {
                    this.setState({ messages: JSON.parse(e.target.result) });
                  };
                  reader.readAsText(file);
                });
                input.click();
              }}
            >
              Upload JSON
            </Button>
            <Button
              onClick={() => {
                let input = document.createElement("input");
                input.type = "file";
                input.accept = ".csv";

                input.addEventListener("change", (e) => {
                  let file = e.target.files[0];
                  Papa.parse(file, {
                    complete: (results) => {
                      let messages = [];
                      for (let line of results.data) {
                        if (!line) continue;
                        let messageObj = {
                          type: line[0], 
                          text: line[1], 
                        };
                        messages.push(messageObj); 
                      }
                      this.setState({ messages: messages }); 
                    } 
                  }); 
                }); 
                input.click(); 
              }}>Upload CSV</Button>
          </Grid>
        </Grid>
      </Card>
    );
  }
}
