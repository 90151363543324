import React from "react";
import $ from "jquery";
import { Card, Typography, TextField, Grid, Button } from "@mui/material";

export class AskQuestion extends React.Component {
    
  constructor(props) {
    super(props);
    this.sendQuestion = this.sendQuestion.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  sendQuestion(e) {
    //Changed to sendQuestion(e)
    let ask = document.querySelector("#question").value;
    console.log(ask);

    $.ajax({
      url: "http://localhost:8080/openai",
      type: "post",
      data: JSON.stringify({
        question: ask,
      }),
      contentType: "application/json",
      dataType: "json",
      success: function (data) {
        console.log(data);
        console.log(data);
        let answerDiv =
          "<div style='font-size: 15px'>Answer: " + data.data + "</div>";
        document.querySelector("#answer-container").innerHTML = answerDiv;
      },
    });
  }
  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.sendQuestion();
    }
  }
  render() {
    return (
        
      <Grid container direction="column" justify="center" alignItems="center">
        <Card style={{ margin: 20, padding: 20, width: 500 }}>
          <Typography variant="h5"> Ask A Question </Typography>
          <TextField id="question" onKeyPress={this.handleKeyPress} />
          <Button onClick={this.sendQuestion}>Submit Question</Button>
          <div id="answer-container"></div>
        </Card>
      </Grid>
    );
  }
}
