import React from "react";
import PropTypes from "prop-types";

export class MessageList extends React.Component {
  renderMessage(messageList) {
    return messageList.map((message, index) => (
        <div className={`message ${message.type === 'left' ? 'left-message' : 'right-message'}`} key={index} >
          <span>{message.text}</span>
      </div>
    ));
  }


  render() {
    const { messages } = this.props;
    return (
      <div className="talk_show">
        <div>{this.renderMessage(messages)}</div>
      </div>
    );

  }
}

MessageList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["right", "left"]),
      text: PropTypes.string,
    })
  ),
};
