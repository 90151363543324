import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./global.css";
import { Card, Typography, TextField, Grid, Button } from "@mui/material";
import { AskQuestion } from "./components/AskQuestion";
import { ChatRoom } from "./components/ChatRoom";
import { Gallery } from "./components/Gallery";
import images from "./action/data";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <div className="container">
    <ChatRoom />
    <Grid container direction="column">
      <AskQuestion />
      <Gallery images={images} />
    </Grid>
  </div>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
