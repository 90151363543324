const images = [
    {
        url: 'https://th.bing.com/th/id/OIP.MfMnlthWKybDC_rKwxrg0gHaE3?pid=ImgDet&rs=1',
        alt: 'Image1',
        width: 200,
        height: 150,
    },
    {
        url: 'https://image.freepik.com/free-photo/no-translate-detected_1160-435.jpg',
        alt: 'Image2',
        width: 200,
        height: 150,
    },
    {
      url: "https://th.bing.com/th/id/R.fdf50972e9473cd986c7bbbd4e1390b5?rik=T8xJ0XtacGBRCg&riu=http%3a%2f%2ftalkimages.cn%2fimages%2fmedium%2f20133077%2ftkf003_680958.jpg&ehk=G34ckdsCquIjz7AhmfgbScJwcvE11d54ENdekgpZV68%3d&risl=&pid=ImgRaw&r=0",
      alt: "Image4",
      width: "200",
      height: "150" ,
    },
    {
      url:"https://img.zcool.cn/community/015aed5a1ea48ba80121713279dd6b.jpg@1280w_1l_2o_100sh.jpg",
      alt:"Image5",
      width:"200",
      height:"150" 
    }
];
export default images;