import React, { Component } from "react";
import { Grid } from "@mui/material";

export class Gallery extends React.Component {
  render() {
    const { images } = this.props;

    return (
      <Grid container direction="row" spacing={2}>
        {images.map((image, index) => (
          <Grid item key={index}>
            <img
              src={image.url}
              alt={image.alt}
              width={image.width}
              height={image.height}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}
